import GATSBY_COMPILED_MDX from "/opt/build/repo/contents/publications/a-mash/index.mdx";
import React from "react";
import HighlightedText from "../../components/HighlightedText";
import Layout from "../../components/Layout";
import {AiOutlineLink, AiOutlineFilePdf, AiFillGithub, AiFillPicture} from "react-icons/ai";
import {BsGearWideConnected} from "react-icons/bs";
import {SEO} from "../../components/Seo";
var PublicationPage = function PublicationPage(_ref) {
  var _data$mdx, _frontmatter$paper_pd, _frontmatter$poster_p;
  var data = _ref.data, children = _ref.children;
  var frontmatter = (_data$mdx = data.mdx) === null || _data$mdx === void 0 ? void 0 : _data$mdx.frontmatter;
  return React.createElement(Layout, null, React.createElement("div", {
    className: "prose"
  }, React.createElement("div", {
    className: "text-2xl font-bold mb-2",
    id: "title"
  }, frontmatter === null || frontmatter === void 0 ? void 0 : frontmatter.title), (frontmatter === null || frontmatter === void 0 ? void 0 : frontmatter.author) && React.createElement(HighlightedText, {
    text: frontmatter === null || frontmatter === void 0 ? void 0 : frontmatter.author,
    query: "Sangwook Lee"
  }), React.createElement("div", {
    className: "mb-2"
  }, frontmatter === null || frontmatter === void 0 ? void 0 : frontmatter.conference)), React.createElement("div", {
    className: "flex mb-6"
  }, (frontmatter === null || frontmatter === void 0 ? void 0 : frontmatter.publication_url) && React.createElement("a", {
    href: frontmatter === null || frontmatter === void 0 ? void 0 : frontmatter.publication_url,
    className: "flex items-center underline mr-2 text-gray-600",
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement(AiOutlineLink, null), " Link"), (frontmatter === null || frontmatter === void 0 ? void 0 : (_frontmatter$paper_pd = frontmatter.paper_pdf) === null || _frontmatter$paper_pd === void 0 ? void 0 : _frontmatter$paper_pd.publicURL) && React.createElement("a", {
    href: frontmatter === null || frontmatter === void 0 ? void 0 : frontmatter.paper_pdf.publicURL,
    download: true,
    className: "flex items-center text-red-600 underline mr-2"
  }, React.createElement(AiOutlineFilePdf, null), "Paper"), (frontmatter === null || frontmatter === void 0 ? void 0 : (_frontmatter$poster_p = frontmatter.poster_pdf) === null || _frontmatter$poster_p === void 0 ? void 0 : _frontmatter$poster_p.publicURL) && React.createElement("a", {
    href: frontmatter === null || frontmatter === void 0 ? void 0 : frontmatter.poster_pdf.publicURL,
    download: true,
    className: "flex items-center text-red-800 underline mr-2"
  }, React.createElement(AiFillPicture, null), "Poster"), (frontmatter === null || frontmatter === void 0 ? void 0 : frontmatter.demo_url) && React.createElement("a", {
    href: frontmatter === null || frontmatter === void 0 ? void 0 : frontmatter.demo_url,
    download: true,
    className: "flex items-center underline mr-2"
  }, React.createElement(BsGearWideConnected, null), "Demo"), (frontmatter === null || frontmatter === void 0 ? void 0 : frontmatter.github_url) && React.createElement("a", {
    href: frontmatter === null || frontmatter === void 0 ? void 0 : frontmatter.github_url,
    download: true,
    className: "flex items-center text-purple-600 underline"
  }, React.createElement(AiFillGithub, null), "Code")), React.createElement("div", {
    className: "prose"
  }, children));
};
var query = "3967953167";
PublicationPage
export default function GatsbyMDXWrapper(props) {
  return React.createElement(PublicationPage, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export var Head = function Head(_ref2) {
  var _data$mdx2, _data$mdx2$frontmatte;
  var data = _ref2.data;
  return React.createElement(SEO, {
    title: "Publications - " + ((_data$mdx2 = data.mdx) === null || _data$mdx2 === void 0 ? void 0 : (_data$mdx2$frontmatte = _data$mdx2.frontmatter) === null || _data$mdx2$frontmatte === void 0 ? void 0 : _data$mdx2$frontmatte.slug)
  });
};
